import { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export const useSearchContext = () => {
    return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
    const [propertyData, setPropertyData] = useState([]);
    const [affordableSolutions, setAffordableSolutions] = useState([]);
    const [searchLat, setSearchLat] = useState(null);
    const [searchLong, setSearchLong] = useState(null);
    const [riskSolutionsContext, setRiskSolutionsContext] = useState(null);
    const [addressNotFound, setAddressNotFound] = useState(false);
    const [allBuilding, setAllBuilding] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [hasLoadedData, setHasLoadedData] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState("all_adaptations");
    const [risksToFilter, setRisksToFilter] = useState([]);
    const [changeError, setChangeError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [searchByAddress, setSearchByAddress] = useState(true);
    const [returnPeriod, setReturnPeriod] = useState(20);
    const [riskYear, setRiskYear] = useState(2030);
    const [radius, setRadius] = useState(0.0009);
    const [streamsData, setStreamsData] = useState([]);
    const [blueSpotsData, setBlueSpotsData] = useState([]);
    const [hasBasement, setHasBasement] = useState({
        yes: false,
        no: false,
        idk: false,
    });
    const [isGroundFloor, setIsGroundFloor] = useState({
        yes: false,
        no: false,
        idk: false,
    });
    const [experiencedSewageBackup, setExperiencedSewageBackup] = useState({
        yes: false,
        no: false,
        idk: false,
    });

    return (
        <SearchContext.Provider value={{ 
        propertyData , setPropertyData,
        affordableSolutions, setAffordableSolutions,
        searchLat, setSearchLat, searchLong, setSearchLong,
        riskSolutionsContext, setRiskSolutionsContext,
        addressNotFound, setAddressNotFound,
        allBuilding, setAllBuilding,
        selectedBuilding, setSelectedBuilding,
        sessionExpired, setSessionExpired,
        hasLoadedData, setHasLoadedData,
        selectedIndex, setSelectedIndex,
        risksToFilter, setRisksToFilter,
        changeError, setChangeError,
        isLoading, setIsLoading,
        searchByAddress, setSearchByAddress,
        returnPeriod, setReturnPeriod,
        riskYear, setRiskYear,
        radius, setRadius,
        streamsData, setStreamsData,
        blueSpotsData, setBlueSpotsData,
        hasBasement, setHasBasement,
        isGroundFloor, setIsGroundFloor,
        experiencedSewageBackup, setExperiencedSewageBackup
        }}>
            {children}
        </SearchContext.Provider>
    );
};
